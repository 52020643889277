body {
  /* margin: 0;
  padding: 0; */
  /* font-family: sans-serif; */
}
a,
a:hover,
a {
  color: white;
  cursor: pointer;
}
.mapboxgl-map {
  top: 0;
  height: 100vh;
  width: 100%;
  position: sticky;
  /* z-index: 1; */
}

.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan,
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan
  .mapboxgl-canvas {
  touch-action: unset;
}

#header {
  text-align: center;
  background-color: #1a2637;
  position: absolute;
  top: calc(50vh - 200px);
  z-index: 10000;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

#header img {
  width: 100%;
}

h1 {
  margin: 0px;
  padding: 0px;
}

#footer {
  width: 100%;
  min-height: 5vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  text-align: center;
  /* line-height: 25px; */
  font-size: 13px;
  pointer-events: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#footer p {
  padding: 10px;
  padding-bottom: 0px;
  margin-top: 0px;
}

.callToAction {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.actionContainer {
  margin: 20px;
  background-color: white;
  color: black;
  border-radius: 50px;
  padding: 15px;
  font-weight: bold;
  /* min-width: 24vw; */
}

.actionContainer:hover {
  transform: translate(1px, -1px);
}

.credits {
  margin: 10px;
}

#story {
  /* transform needed to avoid z-index issues in safari */
  transform: translate3d(0, 0, 0);
  pointer-events: none;
  a {
    pointer-events: auto;
  }
}
#features {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.centered {
  width: 50vw;
  margin: 0 auto;
}
.lefty {
  width: 33vw;
  margin-left: 5vw;
}
.righty {
  width: 33vw;
  margin-left: 62vw;
}
.light {
  color: #444;
  background-color: #fafafa;
}
.dark {
  color: #fafafa;
  background-color: #444;
}
.step {
  padding-bottom: 50vh;
  pointer-events: none;
  margin-bottom: 10vh;
  opacity: 0.25;
}
.step.active {
  opacity: 1;
}

.step div {
  padding: 5px 25px;
  /* line-height: 25px; */
  font-size: 13px;
  border-radius: 20px;
  pointer-events: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.step img {
  width: 100%;
}

@media (max-width: 750px) {
  #features {
    width: 90vw;
    margin: 0 auto;
  }
}
